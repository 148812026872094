import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import AlertCards from '../../components/AlertCards';
import ContentHeader from '../../components/ContentHeader';
import { ContentBody } from './style';
import formatAlertList from '../../util/formatAlertList';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Filters from '../../components/Filters';


function FraudAlerts() {
  const [alertList, setAlertList] = useState([]);
  const [isLoadingAList, setIsLoadingAList] = useState(false);
  const [isOnPagination, setIsOnPagination] = useState(false);
  const [isOnFilter, setIsOnfilter] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const URL_DATA_FETCH_I = "https://p61yf5epyk.execute-api.us-east-1.amazonaws.com/poc/results/";
  const URL_DATA_FETCH_II = "https://ppijkhrivl.execute-api.us-east-1.amazonaws.com/detalhe/recurso";
  const URL_DATA_FETCH_III = "https://zgwru3z423.execute-api.us-east-1.amazonaws.com/results/recurso";
  const DEFAULT_PARAMS = "?"
  const DEFAULT_QTTY = "QTD=5"


  const fetchAList = (url,params)=>{
    setIsLoadingAList(true);
    fetch(`${url}${DEFAULT_PARAMS}${params}`)
      .then((response) => response.json())
      .then((jsonResponse) => {
        const formatedList = jsonResponse.map((alert)=>formatAlertList(alert))
        setAlertList([...alertList, ...formatedList])
        setIsLoadingAList(false);
        setIsOnPagination(false);
      });
  }

  const callFetchAList = ()=>{
    setIsOnfilter(false);
    fetchAList(URL_DATA_FETCH_III,`${DEFAULT_QTTY}&INDEX=0`);
  }
  useEffect(() => {
    callFetchAList()
  },[])

  const handleCardClick =  (alert)=>{
    window.location.pathname = `/alerta/${alert.dataHead.sinistro.value}`;
  }

  const handleScrollPagination  = (event)=>{
    if(isLoadingAList || isOnFilter){return false}
    if (event.currentTarget) {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      if (scrollTop + clientHeight >= (scrollHeight * 0.9)) {
        setIsOnPagination(true);
        fetchAList(URL_DATA_FETCH_III,`${DEFAULT_QTTY}&INDEX=${alertList.length}`);
      }
    }
  }

  const handleFetchByFilters = (filters)=>{
    let params = "";
    if(filters.numeroSinistro){
      params = `${params}&ID_MVI=${filters.numeroSinistro}`
    }
    if(filters.ppf){
      params = `${params}&PPF=${Number(filters.ppf.replace("%",""))/100}`
    }
    if(filters.score){
      params = `${params}&SCORE=${filters.score}`
    }
    setIsLoadingAList(true);
    setIsOnfilter(true)
    fetch(`${URL_DATA_FETCH_III}${DEFAULT_PARAMS}${params}`)
      .then((response) => response.json())
      .then((jsonResponse) => {
        const formatedList = jsonResponse.map((alert)=>formatAlertList(alert))
        setAlertList(formatedList)
        setIsLoadingAList(false);
        setIsOnPagination(false);
      });
  }
  return (
    <>
      <ContentHeader title="Alertas de Fraude"
      style={{marginBottom:"20px"}}
      subtitle={"Selecione o alerta que deseja analisar."}
      breadcrumbs={[{label:"Inicio", src: "/home"}]}
      actionButtons={(isLoadingAList && !isOnPagination)? []:[<Button label={showFilters? "Ocultar Filtros":"Exibir Filtros"} onClick={()=>{
        setShowFilters(!showFilters);
      }}
      key={Math.random()}/>]}/>
      {showFilters &&
        <Filters 
          onSubmit={handleFetchByFilters} 
          onClear={callFetchAList} 
          isDisabled={isLoadingAList}/>
        
      }
      <div style={{ display: (isLoadingAList && !isOnPagination) ? "none":"flex",
        justifyContent: "space-between",
        position: "relative"}}>
        {/* <Button label="Filtrar"/> */}
        <p style={{margin:"0px"}}>Exibindo {alertList.length} alertas</p>
      </div>
      <ContentBody onScroll={handleScrollPagination} style={{ overflowY: (isLoadingAList && !isOnPagination) ? "initial":"scroll",}}>

        {(isLoadingAList && !isOnPagination) && 
        (<>
          <Skeleton height={"170px"} style={{marginTop: "10px"}}/>
          <Skeleton height={"170px"} style={{marginTop: "10px"}}/>
          <Skeleton height={"170px"} style={{marginTop: "10px"}}/>
          <Skeleton height={"170px"} style={{marginTop: "10px"}}/>
        </>
        )}
        {((isLoadingAList && !isOnPagination) && alertList.length > 0) ? (false):(
          alertList.map((alert, index)=>{
            return <AlertCards
            key={`AC-${index}`}
            alertData={alert}
            onClick={(i)=>{
              handleCardClick(alert)
            }}
            />
          })
        )}
        {isOnPagination && 
          <Skeleton height={"170px"} style={{margin: "10px 0px"}}/>
        }
      </ContentBody>
    </>
  )
}

export default FraudAlerts;