import React from 'react';
import { ContentHeaderBreadCrumb, ContentHeaderContainer, ContentHeaderSubTitle, ContentHeaderTitle } from './style';


function ContentHeader({title, subtitle, breadcrumbs, actionButtons, style}) {

  return (
    <ContentHeaderContainer style={style}>
      <div>
        {breadcrumbs?.length > 0 && breadcrumbs.map((breadcrumb)=>{
          return <ContentHeaderBreadCrumb key={Math.random()}><a href={breadcrumb.src}>{`${breadcrumb.label} > `}</a></ContentHeaderBreadCrumb>
        })}
        <ContentHeaderTitle>{title}</ContentHeaderTitle>
        {subtitle && <ContentHeaderSubTitle>{subtitle}</ContentHeaderSubTitle>}
      </div>

      {actionButtons?.length > 0  && 
        <div>
          {actionButtons.map((button)=>{
            return button;
          })}
        </div>
      }
    </ContentHeaderContainer>
  )
}

export default ContentHeader;