import styled from 'styled-components'

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // width: 27rem;
    label{
        font-weight: 600;
        font-size: 12px;
    }
`
export const InputCustom = styled.input`
    height: 30px;
    &:disabled{
        cursor: not-allowed;
    }
`