import React from 'react';
import { InputContainer, InputCustom } from "./style";


function Input(props) {
  const {value,onChange,placeholder,label,disabled, style} = props;

  return (
    <>
        <InputContainer style={style}>
          <label htmlFor="">{label}</label>
          <InputCustom type="text" placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          />
        </InputContainer>
      </>
  )
}

export default Input;
