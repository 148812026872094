import React, { useEffect, useState } from 'react';
import { ModalContainer, ModalOverlay } from "./style";
import Button from '../Button';
import Input from '../Input';
import TextArea from '../TextArea';
import InputNDButton from '../InputNDButton';

function RulesModal(props) {
  const {ruleToEdit = null, onSubmit} = props;

  const [rule,setRule] = useState({
    name: '',
    description: '',
    scorePoints: ''
  });

  const [queryReturn, setQueryReturn ] = useState('');
  const [useQueryIA, setUseQueryIA ] = useState(false);
  const [searchTextIA, setSearchTextIA ] = useState('');

  const [isLoadingSearchIA, setIsLoadingSearchIA ] = useState(false);



  const FETCH_URL_IA = "https://2vbjwt8omf.execute-api.us-east-1.amazonaws.com/sql/poc?pergunta=";


  useEffect(()=>{
    if(ruleToEdit){
      setRule(ruleToEdit)
    }
  },[])

  const handleSubmitRules = () => {
    if(rule.nome && rule.descricaoRegra && rule.sqloRegra && rule.pesoRegra){
      onSubmit({
        method: ruleToEdit.id ? "PUT" : "POST",
        body: JSON.stringify(rule), 
        headers: {
          "Content-Type": "application/json",
        },
      })
      props.onClose()
    }
  }

  const handleExecuteQuery = () => {
    console.log("exec");
  }

  const handleUseIA = (searchText) => {
    if(!searchText){ return false};
    
    setIsLoadingSearchIA(true);
    fetch(`${FETCH_URL_IA}${searchText}`)
    .then((response) => response.json())
    .then((jsonResponse) => {
      setIsLoadingSearchIA(false);
      setRule({...rule,sqloRegra:jsonResponse})
    });
  }


  return (
    <>    
      <ModalOverlay onClick={props.onClose}/>
      <ModalContainer>
        <div>
          <h2 style={{margin:"0px", marginBottom:"10px"}}>{props.title}</h2>
        </div>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", columnGap:"5px"}}>
          <Input
          label='Nome' 
          style={{width:"45%"}}
          value={rule.nome}
          onChange={(e)=>{
            setRule({...rule,nome:e.target.value})
          }} />
          <Input
          label='Peso'
          style={{width:"45%"}}
          value={rule.pesoRegra}
          onChange={(e)=>{
            setRule({...rule,pesoRegra:e.target.value})
          }} />
        </div>
        <div>
          <Input
            label='Descrição' 
            value={rule.descricaoRegra}
            onChange={(e)=>{
              setRule({...rule,descricaoRegra:e.target.value})
            }} />
        </div>         
        <div style={{marginTop:"20px",}}>
          <InputNDButton
          label='Assistente Inteligente para geração de query ( IA - Opcional )'
          placeholder="Quais sinistros possuem veículos emplacados depois de 2 anos do ano de fabricação?"
          value={searchTextIA}
          isLoading={isLoadingSearchIA}
          onChange={(e)=>{
            setSearchTextIA(e.target.value)
          }}
          onSubmit={()=>{
            handleUseIA(searchTextIA)}} />

          <br/>
          <TextArea
          label='Consulta da regra - QUERY SQL'
          value={rule.sqloRegra}
          disabled={isLoadingSearchIA}
          onChange={(e)=>{
            setRule({...rule,sqloRegra:e.target.value})
          }} />
          <div style={{display:"flex", flexDirection:"row", marginTop:"10px", justifyContent:"end"}}>
            <Button label="Testar Regra" onClick={handleExecuteQuery}/>
          </div>
          {queryReturn && 
            <TextArea
            label='Resultado da pesquisa'
            value="Retorno API"
            disabled
            />
          }
        </div>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", marginTop:"10px", columnGap:"5px"}}>
          <Button label="Cancelar" onClick={props.onClose}/>
          <Button label="Salvar" onClick={handleSubmitRules}/>
        </div>
      </ModalContainer>
    </>
  )
}

export default RulesModal;
