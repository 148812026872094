import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import ContentHeader from '../../components/ContentHeader';
import { ContentBody, RulesCard } from './style';
import DataDisplay from '../../components/DataDisplay';
import RulesModal from '../../components/RulesModal';
import Skeleton from 'react-loading-skeleton';


function RulesManager() {
  const [rulesList, setRulesList] = useState([]);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [ruleToEdit, setRuleToEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const URL_DATA_FETCH = "https://poccnseg.ebixlatinamerica.com.br/api";

  const fetchRules = async (url, params={method:"GET"})=>{
    setIsLoading(true);
    await fetch(`${url}`, params)
    .then((response) => response.json())
    .then((jsonResponse) => {
      if(params.method === "GET"){
        setRulesList(jsonResponse)
      }else{
        fetchRules(URL_DATA_FETCH,{method:"GET"})
      }
      setIsLoading(false);
    });
  }
  const fetchSubmit = async (url, params={method:"GET"})=>{
    setIsLoading(true);
    await fetch(`${url}`, params)
    .then((response) => {
      console.log(response)
      window.location.reload();
    });
  }
  const handleDeleteRule = (rule)=>{
    fetchSubmit(`${URL_DATA_FETCH}/${rule.id}`, {method:"DELETE"})
  }

  const handleSubmitRule = (params)=>{
    fetchSubmit(`${URL_DATA_FETCH}`, params)
  }

  useEffect(()=>{
    fetchRules(URL_DATA_FETCH)
  },[])
  return (
    <>
      <ContentHeader title="Gerenciador de Regras" 
      subtitle={"Gerencie regras para alerta de fraudes"}
      breadcrumbs={[{label:"Inicio", src: "/home"}]}/>
      
      <div style={{    display: isLoading ? "none":"flex",
        justifyContent: "space-between",
        position: "relative"}}>
        <p>Exibindo {rulesList.length} regras</p>
        <Button label="Adicionar" onClick={(e)=>{
          setRuleToEdit({});
          setShowRulesModal(true);
        }}/>
      </div>
      <ContentBody>
        {isLoading ? (
          <>
            <Skeleton height={"150px"}/>
            <Skeleton height={"150px"}/>
            <Skeleton height={"150px"}/>
            <Skeleton height={"150px"}/>
          </>
        ):(
          rulesList?.map((rule,index)=>{
            return (
              <RulesCard key={`RC-${index}`}>
                <div>
                  <div style={{display: "flex", columnGap: "20px"}}>
                    <DataDisplay
                    style={{width: "50%"}}
                    label="Nome" value={rule.nome}/>
                    <DataDisplay
                    label="Peso" value={rule.pesoRegra}/>
                  </div>
                  <div>
                    <DataDisplay
                    label="Descrição" value={rule.descricaoRegra}/>
                  </div>
                </div>
                <div style={{display: "flex", rowGap: "2px", flexDirection:"column"}}>
                  <Button label="Editar" style={{width:"100%"}} onClick={()=>{setRuleToEdit(rule); setShowRulesModal(true);}}/>
                  <Button label="Excluir" onClick={()=>{
                    handleDeleteRule(rule)
                  }}/>
                </div>
              </RulesCard>
            );
            })
        )}

      </ContentBody>

      {
        showRulesModal && 
        <RulesModal title="Adicionar Regra" 
        ruleToEdit={ruleToEdit} 
        onClose={()=>{setShowRulesModal(false);}} 
        onSubmit={handleSubmitRule}/>
      }
    </>
  )
}

export default RulesManager;