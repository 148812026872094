import styled from 'styled-components'


export const CardContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    border-radius: 5px;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    // &:hover{
    //     cursor: pointer;
    //     background-color: #d6d6d6;
    // }
`
export const HeaderCardContainer = styled.div`
display: flex;
justify-content: space-between;
border-bottom: 1px solid #c1c1c1;
padding-bottom: 5px;
.leftContent{
    display:flex;
    gap: 25px
}
div {
    span{
        font-weight: 600;
        font-size: 12px;
    }
    p{
        font-size:18px;
        margin: 0;
    }
}
`
export const BodyCardContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
gap:10px;
div{
    width: 9rem;
    overflow-wrap: anywhere;
    span{
        font-weight: 600;
        font-size: 12px;
    }
    p{
        font-size:14px;
        margin: 0;
    }
}
`