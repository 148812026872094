import styled from 'styled-components'

export const ModalOverlay = styled.div`
    width: 100%;
    height: 100vmax;
    z-index: 9998;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`
export const ModalContainer = styled.div`
    width: 70%;
    height: auto;
    background-color: #fff;
    z-index: 9999;
    position: fixed;
    left: 12%;
    top: 5%;
    border-radius: 5px;
    padding: 30px;
`