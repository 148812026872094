import styled from 'styled-components'


export const CardContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding: 20px;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    border-radius: 5px;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

    table{
        td, th{
            padding: 0px 5px;
        }
    }
`