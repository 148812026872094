import React from 'react';
import ContentHeader from '../../components/ContentHeader';
import LinkCard from '../../components/LinkCard';


function Home() {

  return (
    <>
      <ContentHeader title="Análise Inteligente de Propensões a Fraude (IA)"/>
      <div>
        <h3>Funcionalidades</h3>
        <div style={{display: "flex",
          columnGap: "20px",
          flexDirection: "row",
          marginBottom: "20px"}}>
          <a href="/lista-de-alertas">
            <LinkCard
              text={"Alertas de Fraude"}/>
          </a>
          <a href="/gerenciar-regras">
            <LinkCard
              text={"Gerenciar Regras"}/>
          </a>
        </div>
      </div>
      <div className='bg_home'/>
    </>
  )
}

export default Home;