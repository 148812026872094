import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';


import './index.css';
import reportWebVitals from './reportWebVitals';
import NoPage from './pages/noPage';
import FraudAlerts from './pages/fraudAlerts';
import AlertViewer from './pages/alertViewer';
import RulesManager from './pages/rulesManager';

import Home from './pages/home';
import globalReducer from './ducks/globalReducer';

import { createStore, combineReducers } from 'redux';
import Login from './pages/login';

const store = createStore(combineReducers({
  globalReducer
}))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <div className="container">
    <header>
      <a href='/'>
        <i className='logo'/>
      </a>
      <h3 style={{color:"#FFFFFF"}}>Plataforma de Prevenção a Fraudes de Seguros</h3>
    </header>
    <main>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/home" element={<Home />}/>
        <Route path="/lista-de-alertas" element={<FraudAlerts />}/>
        <Route path="/alerta/:alertID" element={<AlertViewer />}/>
        <Route path="/gerenciar-regras" element={<RulesManager />}/>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
    </main>
    <footer></footer>
  </div>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
