import React from 'react';
import { DataDisplayContainer } from './style';

function DataDisplay({label,value,valueStyle, style}) {

  return (
    <DataDisplayContainer style={style}>
      <span>{label}</span>
      <p style={valueStyle}>{value}</p>
    </DataDisplayContainer>
  )
}

export default DataDisplay;
