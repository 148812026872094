import React, { useEffect, useState } from 'react';
import ContentHeader from '../../components/ContentHeader';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import NormalCard from '../../components/NormalCard';
import BrokenRulesCard from '../../components/BrokenRulesCard';
import formatAlert from '../../util/formatAlert';
import Skeleton from 'react-loading-skeleton';
import ConcludeAlertModal from '../../components/ConcludeAlertModal';

function AlertViewer() {
  let { alertID } = useParams();
  const [showConcludeAlertModal, setShowConcludeAlertModal] = useState(false);
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  const [isLoadingFetchRules, setIsLoadingFetchRules] = useState(true);
  const [alerta, setAlerta] = useState([]);
  const [brokenRules, setBrokenRules] = useState([]);




  const URL_DATA_FETCH = "https://ppijkhrivl.execute-api.us-east-1.amazonaws.com/detalhe/recurso?ID_MVI=";
  const URL_DATA_FETCH_III = "https://zgwru3z423.execute-api.us-east-1.amazonaws.com/results/recurso?ID_MVI=";
  const URL_BROKEN_RULES = "https://8na5wt9rl8.execute-api.us-east-1.amazonaws.com/regras_violadas/recurso?ID_MVI=";


  const fetchBrokenRules = ()=>{
    setIsLoadingFetchRules(true);
    fetch(URL_BROKEN_RULES + alertID)
    .then((response) => response.json())
    .then((jsonResponse) => {
      setBrokenRules(jsonResponse)
      setIsLoadingFetchRules(false);
    });
  }
  useEffect(() => {
    if(!alertID){
      window.location.pathname = `/lista-de-alertas`;
      return
    };
    setIsLoadingFetch(true);
    fetch(URL_DATA_FETCH_III + alertID)
      .then((response) => response.json())
      .then((jsonResponse) => {
        const formatedList = jsonResponse.map((alert)=>formatAlert(alert))
        setAlerta(formatedList)
        setIsLoadingFetch(false);
        fetchBrokenRules()
      });
  },[])


  return (
    <>
      <ContentHeader
      title="Visualizar Alerta" 
      breadcrumbs={[{label:"Inicio", src: "/home"},{label:"Alertas", src: "/lista-de-alertas"}]}
      actionButtons={isLoadingFetch? []:[<Button label="Encerrar alerta" onClick={()=>{
        setShowConcludeAlertModal(true);
      }}
      key={Math.random()}/>]}/>
      {isLoadingFetch && (
        
          <>
            <Skeleton height={"170px"} style={{marginBottom:"40px"}}/>
            <Skeleton height={"200px"}/>
          </>
        
      )}

      {(!isLoadingFetch && alerta.length > 0) ? (
        <div>
          <div>
            <h3>Dados</h3>
            <NormalCard data={alerta[0].data}/>
          </div>
          {!isLoadingFetchRules ? (
            <div>
              <h3>Regras Violadas</h3>
              <BrokenRulesCard
              brokenRules={brokenRules}
              />
            </div>
          ):(
            <Skeleton height={"200px"}/>
          )}
          
        </div>
      ):(false)
      }

      {showConcludeAlertModal &&
        <ConcludeAlertModal
        title='Encerrar Alerta'
        onClose={()=>{
          setShowConcludeAlertModal(false);
        }}
        onCancel={()=>{
          setShowConcludeAlertModal(false);
        }}
        onSubmit={()=>{
          console.log("ALERTA ENCERRADO")
          setShowConcludeAlertModal(false);
        }}
        />
      }
      
    </>
  )
}

export default AlertViewer;