const percentFormat = (percent)=> Number(percent).toLocaleString("pt-br",{style: 'percent', minimumFractionDigits:2});


export const formatAlertList = (list)=> {
   return { alertID: list.ID_PRINC,
        dataHead: {
          sinistro: {label: "Sinistro", value: list["﻿ID_MVI"] || list['id_mvi']},
          score: {label: "Score", value: list.SCORE || list.score},
          ppf: {label: "PPF", value: percentFormat(list.PPF || list.ppf)},
          status: {label: "Status", value: list.DS_STATUS_SINISTRO || list.ds_status_sinistro}
        },
        dataBody: [
          {label: "Evento", value: "-"},
          {label: "Seguradora", value: list.NM_RAZAO_SOCIAL || list.nm_razao_social},
          {label: "Natureza", value: list.ds_natureza},
          {label: "Data Ocorrencia", value: (list.DT_OCORRENCIA || list.dt_ocorrencia).replaceAll("-","/")},
          {label: "Data Aviso", value: (list.DT_AVISO || list.dt_aviso).replaceAll("-","/")},
          {label: "Data Inicio Vigencia", value: (list.DT_INI_VIGENCIA || list.dt_ini_vigencia).replaceAll("-","/")},
          {label: "Data Alerta", value: (list.DATA_ALERTA || list.data_alerta).replaceAll("-","/")},
          {label: "Grupo Mapeado", value: "-"},
          {label: "Cluster", value: "-"},
          {label: "Responsável", value: list.NOME_PARTICIPANTE || list.nome_participante},
          {label: "Alçada Técnica", value: "-"},
        ]}
}

export default formatAlertList