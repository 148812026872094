import React  from 'react';
import { ModalContainer, ModalOverlay } from "./style";
import Button from '../Button';
import TextArea from '../TextArea';

function ConcludeAlertModal(props) {

  return (
    <>    
      <ModalOverlay onClick={props.onClose}/>
      <ModalContainer>
        <div>
          <h2 style={{margin:"0px", marginBottom:"10px"}}>{props.title}</h2>
        </div>
        <div>
          <TextArea
          label='Conclusão da Analise'
          value={""}
           />
        </div>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", marginTop:"10px", columnGap:"5px"}}>
          <Button label="Cancelar" onClick={props.onClose}/>
          <Button label="Concluir" onClick={props.onClose}/>
        </div>
      </ModalContainer>
    </>
  )
}

export default ConcludeAlertModal;
