import React from 'react';
import { InputContainer, InputCustom } from "./style";


function InputNDButton(props) {
  const {value,onChange,placeholder,label,disabled, onSubmit, isLoading} = props;

  return (
    <>
        <InputContainer>
          <label htmlFor="">{label}</label>
          <InputCustom type="text" placeholder={placeholder}
          maxlength="250"
          value={value}
          onChange={onChange}
          disabled={disabled}
          />
          <button onClick={onSubmit} disabled={isLoading}>{isLoading ? "Carregando":"Gerar query"}</button>
        </InputContainer>
      </>
  )
}

export default InputNDButton;
