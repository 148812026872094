import styled from 'styled-components'

export const DataDisplayContainer = styled.div`
    // width: 9rem;
    span{
        font-weight: 600;
        font-size: 12px;
    }
    p{
        font-size:14px;
        margin: 0;
    }

`