import styled from 'styled-components'


export const ContentBody = styled.div`
    margin-top: 10px;
    height: 700px;
    padding: 0px 3px;

    &::-webkit-scrollbar {
        width: 6px;               /* width of the entire scrollbar */
      }
      
    &::-webkit-scrollbar-track {
        background: transparent;        /* color of the tracking area */
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #0095da;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        border: 3px solid #0095da;  /* creates padding around scroll thumb */
    }
`