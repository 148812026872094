import styled from 'styled-components'


export const ContentHeaderContainer = styled.div`
    margin-top:20px;
    margin-bottom: 90px;
    display: flex;
    justify-content: space-between;
`
export const ContentHeaderTitle = styled.h1`
    margin:0px;
`
export const ContentHeaderSubTitle = styled.p`
    margin:0px;
`
export const ContentHeaderBreadCrumb = styled.span`

`