import styled from 'styled-components'

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    // width: 27rem;
    label{
        font-weight: 600;
        font-size: 12px;
    }

    button{
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px 15px;
        width: fit-content;
        background-color: rgb(64, 61, 74);
        border: 0px solid transparent;
        border-radius: 0px 2px 2px 0px;
        cursor: pointer;
        color: #FFF;
        letter-spacing: 1px;
        transition: all 0.3s ease 0s;
        &:disabled{
            cursor: not-allowed;
        }
    }
`
export const InputCustom = styled.input`
    height: 30px;
    padding-right: 150px;
    &:disabled{
        cursor: not-allowed;
    }
`