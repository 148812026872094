import styled from 'styled-components'


export const CardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 20px;
    transition: all 0.2s ease 0s;
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    width: 20rem;
    font-weight: normal;
    
    &:hover{
        cursor: pointer;
        border: 1px solid #0095da;
        color: #0095da;
    }
`