import React, { useState } from 'react';
import { FiltersContainer } from "./style.js";
import Button from '../Button/index.jsx';
import Input from '../Input/index.jsx';


function Filters(props) {
  const [numeroSinistro, setNumeroSinistro] = useState("");
  const [ppf, setPpf] = useState("");
  const [score, setScore] = useState("");
  const FILTERS_LIST = [
    {
      label:"Número do sinistro",
        placeholder: "Ex.: 138461254",
        value: numeroSinistro,
        onChange:(e)=>{
          setNumeroSinistro(e.target.value)
        }
    },
    {
      label:"Percentual de Propensão a Fraude (PPF)",
        placeholder: "Ex.: 98% (Acima de 98%)",
        value: ppf,
        onChange:(e)=>{
          setPpf(e.target.value)
        }
    },
    {
      label:"Score",
        placeholder: "Ex.: 9",
        value: score,
        onChange:(e)=>{
          setScore(e.target.value)
        }
    },
  ]

  const handleClearFilters = ()=> {
    setNumeroSinistro("")
    setPpf("")
    setScore("")
    props.onClear()
  }
  return (
    <FiltersContainer >
      {FILTERS_LIST.map((filter,index)=>{
        return <Input
          label={filter.label}
          placeholder={filter.placeholder}
          value={filter.value}
          onChange={filter.onChange}
          key={`F-${index}`}
          disabled={props.isDisabled}
        />
      })}

      <div style={{display:"flex", flexDirection:"row", justifyContent:"center", marginTop:"10px", columnGap:"5px"}}>
        <Button label="Limpar" onClick={handleClearFilters}/>
        <Button label="Filtrar" onClick={()=>{
          if(numeroSinistro || ppf || score){
            props.onSubmit({numeroSinistro,ppf,score})
          }
        }}/>
      </div>
    </FiltersContainer>
  )
}

export default Filters;

