import React from 'react';
import { BodyCardContainer, CardContainer, HeaderCardContainer } from "./style.js";
import DataDisplay from '../DataDisplay/index.jsx';
import Button from '../Button/index.jsx';

import 'react-loading-skeleton/dist/skeleton.css';


function AlertCards({alertData, onClick}) {
  const handlePPFColor = (ppf)=>{
    const SEMAFORO = {verde: "green", laranja: "orange", vermelho: "red"}
    const MIN_FOR_GREEN = 90.99;
    const MAX_FOR_RED = 15.99;
    const ppfFormated = Number(ppf.replace("%","").replaceAll(",","."));
    console.log(ppfFormated)
    if(ppfFormated >= MIN_FOR_GREEN){
      return SEMAFORO.vermelho
    }else if(ppfFormated <= MAX_FOR_RED){
      return SEMAFORO.verde
    }else{
      return SEMAFORO.laranja
    }
  }
  return (
    <CardContainer >
      <HeaderCardContainer>
        <div className='leftContent'>
          <DataDisplay label={alertData.dataHead['sinistro']?.label} value={alertData.dataHead['sinistro']?.value}/>
          <DataDisplay label={alertData.dataHead['score']?.label} value={alertData.dataHead['score']?.value}/>
          <DataDisplay 
            label={alertData.dataHead['ppf']?.label}
            value={alertData.dataHead['ppf']?.value}
            valueStyle={{color: handlePPFColor(alertData.dataHead['ppf']?.value), fontWeight:"bold"}}/>
          <DataDisplay label={alertData.dataHead['status']?.label} value={alertData.dataHead['status']?.value}/>
        </div>
        <div className='rightContent'>
          <Button label="Visualizar" onClick={onClick}/>
        </div>
      </HeaderCardContainer>
      <BodyCardContainer>
        {
          alertData.dataBody?.map((data,index)=>{
            return <DataDisplay label={data.label} value={data.value} key={`DB-${index}`}/>
          })
        }
      </BodyCardContainer>
    </CardContainer>
  )
}

export default AlertCards;