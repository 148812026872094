import styled from 'styled-components'


export const Button = styled.button`
    padding: 10px 15px;
    width: fit-content;
    background-color: rgb(64, 61, 74);
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    color: #FFF;
    letter-spacing: 1px;
    transition: all 0.3s ease 0s;
    &:hover{
        background-color: #0095da;
    }
`