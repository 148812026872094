const SELECTED_ALERT_CHANGED = 'SELECTED_ALERT_CHANGED';

export function inputChange(value){
  return {
    type: SELECTED_ALERT_CHANGED,
    value
  }
}

const initialState = {
  selectedAlertID: 'inicial'
};

export default function reducer(state = initialState, action){
  switch (action.type){
    case SELECTED_ALERT_CHANGED:
      return {
          ...state,
          selectedAlertID: action.value
        };
      
    default:
      return state;
  }
}