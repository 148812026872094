import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    label{
        font-weight: 600;
        font-size: 12px;
    }
`
