import React from 'react';
import { CardContainer } from "./style.js";


function BrokenRulesCard({brokenRules}) {
  console.log(brokenRules)
  return (
    <CardContainer>
      {brokenRules.length > 0 && brokenRules[0] ?(
      <table style={{width:"100%",borderSpacing: "0px"}}>
        <thead>
          <tr style={{fontSize:"12px"}}>
            <th style={{textAlign:"initial", borderBottom:"1px solid #ccc"}}>Nome</th>
            <th style={{textAlign:"initial", borderBottom:"1px solid #ccc"}}>Descrição</th>
            <th style={{textAlign:"initial", borderBottom:"1px solid #ccc"}}>Peso</th>                
          </tr>
        </thead>
        <tbody>
          {brokenRules.map((rule)=>{
            return (<tr key={Math.random()}>
              <td>{rule.nome_regra || "-"}</td>
              <td>{rule.dsc_regra || "-"}</td>
              <td>{rule.peso_regra || "-"}</td>
            </tr>)
          })}
        </tbody>
      </table>

      ):(
        <p>Nenhuma regra registrada</p>
      )
      }
    </CardContainer>
  )
}

export default BrokenRulesCard;
