import React from 'react';
import { Button as ActionButton } from "./style.js";

function Button({className,label,onClick,isLoading,style}) {
  return (
    <div>
        <ActionButton onClick={onClick} style={style} className={className} disabled={isLoading}>{label}</ActionButton>
    </div>
  )
}

export default Button;
