import React from 'react';
import { Container } from "./style";


function TextArea(props) {
  const {value,onChange,label} = props;

  return (
    <>
        <Container>
          <label>{label}</label>
          <textarea
            rows="5" cols="50"
            value={value}
            onChange={onChange}
            disabled={props.disabled}/>         
        </Container>
      </>
  )
}

export default TextArea;
