import React from 'react';
import {BodyCardContainer, CardContainer, HeaderCardContainer} from "./style.js";
import DataDisplay from '../DataDisplay/index.jsx';
import Button from '../Button/index.jsx';


function LinkCard({text, onClick}) {

  return (
    <CardContainer onClick={onClick}>
      {/* <i></i> */}
      <p>{text}</p>
    </CardContainer>
  )
}

export default LinkCard;
