import React from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';


function Login() {

  return (
      <div style={{width:"50%", padding:"100px 0px", boxSizing: "border-box", margin:"0 auto"}}>
        <div>
          <Input
          label="Login"
          />
          <Input
          label="Senha"
          />
        </div>
        <div style={{display:"flex", justifyContent:"center", marginTop:"10px", columnGap:"5px"}}>
          <a href='/home'>
            <Button label="Login"/>
          </a>
        </div>
      </div>
  )
}

export default Login;