
export const formatAlert = (alert)=> {
  return {
    alertID: 9,
    data: [
      {label: "MVI", value: alert.id_mvi},
      {label: "Seguradora", value: alert.nm_razao_social},
      {label: "Grupo Ramo", value: alert.grupo_ramo},
      {label: "Natureza", value: alert.ds_natureza},
      {label: "Tipo Participante", value: alert.tp_partic},
      {label: "CPF Participante", value: alert.cpf_cnpj_participante},
      {label: "Data Ocorrencia", value: alert.dt_ocorrencia.replaceAll("-","/")},
      {label: "Data Aviso", value: alert.dt_aviso.replaceAll("-","/")},
      {label: "Responsável", value: "-"},
      {label: "Grupo", value: "-"},
    ],
    brokenRules: alert.regras.replace("[","").replace("]","").split(",")
  }
}

export default formatAlert