import React from 'react';
import {CardContainer, DataDisplayCustom} from "./style.js";
// import DataDisplay from '../DataDisplay/index.jsx';
function NormalCard({data}) {

  return (
    <CardContainer >
      {
        data?.map((data)=>{
          return <DataDisplayCustom label={data.label} value={data.value} key={Math.random()}/>
        })
      }
    </CardContainer>
  )
}

export default NormalCard;
