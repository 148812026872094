import styled from 'styled-components'


export const ContentBody = styled.div`
    margin-top: 10px;
`


export const RulesCard = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 5px;
    column-gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
`